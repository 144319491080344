<template>
  <div class="perfectInfo">
    <div class="infoHeader">完善信息，获得特权</div>
    <div class="progress">
      <img src="@/assets/images/preciseMatch/precise_content.png" alt="" />
      <div class="progress_bar flex">
        <el-progress :percentage="percent" :color="customColor"></el-progress>
      </div>
    </div>
    <PreciseMatchInfoForm v-model:percentage="percent" />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import PreciseMatchInfoForm from './PreciseMatchInfoForm.vue'
export default defineComponent({
  props: {
    percentage: {
      type: Number,
      default: 0
    }
  },
  components: {
    PreciseMatchInfoForm
  },
  setup(props, ct) {
    // const percentage = ref<number | null>()
    const percent = computed({
      get() {
        return props.percentage
      },
      set(v) {
        ct.emit('update:percentage', v)
      }
    })
    const customColor = ref('#FDFFFA')
    return {
      percent,
      customColor
    }
  }
})
</script>
<style lang="scss" scoped>
.perfectInfo {
  width: 897px;
  margin: auto;
  padding-top: 42px;
  .infoHeader {
    text-align: center;
    font-size: 38px;
    font-weight: 300;
    color: #333333;
  }
  .progress {
    position: relative;
    margin-top: 50px;
    .progress_bar {
      position: absolute;
      align-items: center;
      top: 27px;
      left: 122px;
      & > span:first-of-type {
        font-size: 16px;
        color: #412301;
        margin-right: 12px;
      }
    }
    & > span {
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: #412301;
      margin-top: -40px;
      margin-left: 63px;
    }
  }
}
:deep(.el-progress-bar) {
  width: 700px;
  .el-progress-bar__outer {
    height: 4px !important;
    border-radius: 7px;
    background: #867268;
  }
}
</style>
