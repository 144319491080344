
import { defineComponent, ref, computed } from 'vue'
import PreciseMatchInfoForm from './PreciseMatchInfoForm.vue'
export default defineComponent({
  props: {
    percentage: {
      type: Number,
      default: 0
    }
  },
  components: {
    PreciseMatchInfoForm
  },
  setup(props, ct) {
    // const percentage = ref<number | null>()
    const percent = computed({
      get() {
        return props.percentage
      },
      set(v) {
        ct.emit('update:percentage', v)
      }
    })
    const customColor = ref('#FDFFFA')
    return {
      percent,
      customColor
    }
  }
})
