<template>
  <div class="InfoForm">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="100px"
      ref="InfoForm"
      class="demo-ruleForm"
    >
      <el-form-item label="企业名称：" prop="entName">
        <BaseGetInfoInput
          v-model="ruleForm.entName"
          v-model:sc="ruleForm.sc"
          v-model:registerMoney="ruleForm.registerMoney"
          v-model:registerAddress="ruleForm.registerAddress"
        />
      </el-form-item>
      <el-form-item label="联系人：" prop="contact">
        <el-input
          v-model="ruleForm.contact"
          autocomplete="off"
          placeholder="请输入联系人"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱：" prop="email">
        <el-input
          v-model="ruleForm.email"
          autocomplete="off"
          placeholder="请输入邮箱地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码：" prop="sc">
        <el-input
          v-model="ruleForm.sc"
          autocomplete="off"
          placeholder="请输入统一社会信用代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="注册资金（万）：" prop="registerMoney">
        <el-input
          v-model="ruleForm.registerMoney"
          autocomplete="off"
          placeholder="请输入注册资金（万）"
        ></el-input>
      </el-form-item>
      <el-form-item label="注册地址：" prop="registerAddress">
        <el-input
          v-model="ruleForm.registerAddress"
          autocomplete="off"
          placeholder="请输入注册地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="有无高层次人才：" prop="isHigh">
        <el-radio-group v-model="ruleForm.isHigh">
          <el-radio label="1">有</el-radio>
          <el-radio label="0">无</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="有无设备投入：" prop="isEquipment">
        <el-radio-group v-model="ruleForm.isEquipment">
          <el-radio label="1">有</el-radio>
          <el-radio label="0">无</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="营收规模（万元）：" prop="operateIncome">
        <el-select v-model="ruleForm.operateIncome" placeholder="请选择">
          <el-option value="100以下"></el-option>
          <el-option value="100-2000"></el-option>
          <el-option value="2000-5000"></el-option>
          <el-option value="5000以上"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="净资产（万元）：" prop="totolProperty">
        <el-select v-model="ruleForm.totolProperty" placeholder="请选择">
          <el-option value="0以下"></el-option>
          <el-option value="0-100"></el-option>
          <el-option value="100以上"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="员工人数：" prop="employees">
        <el-select v-model="ruleForm.employees" placeholder="请选择">
          <el-option value="1-50"></el-option>
          <el-option value="50-100"></el-option>
          <el-option value="100-200"></el-option>
          <el-option value="200以上"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="主营产品：" prop="mainProduct">
        <el-input
          v-model="ruleForm.mainProduct"
          autocomplete="off"
          placeholder="请输入主营产品"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="经营地址：" prop="businessAddress">
        <el-input
          v-model="ruleForm.businessAddress"
          autocomplete="off"
          placeholder="请输入经营地址"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="formBtn pointer" @click="submit">提交</div>
    <div class="tip flex">
      <img src="@/assets/images/preciseMatch/Info.png" alt="" />
      <span>我们注重信息保护，承诺无授权不对外提供</span>
    </div>
  </div>
</template>
<script lang='ts'>
import { defineComponent, onMounted, ref, watch } from 'vue'
import rules from './validate'
import { entInfo, verify, add } from '@/api/preciseMatch'
import { useRouter } from 'vue-router'
import BaseGetInfoInput from '@/common/BaseGetInfoInput.vue'

interface IinfoRef {
  validate: (cb: (params: boolean) => void) => void;
}
interface Iform {
  entName: string;
  contact: string;
  email: string;
  sc: string;
  registerMoney: string;
  registerAddress: string;
  isHigh: string;
  isEquipment: string;
  operateIncome: string;
  totolProperty: string;
  employees: string;
  mainProduct: string;
  businessAddress: string;
  [key: string]: any;
}
interface Ires {
  status: string;
  message: string;
  data?: any;
}

export default defineComponent({
  components: {
    BaseGetInfoInput
  },
  props: {
    percentage: {
      type: Number,
      required: true
    }
  },
  setup (props, context) {
    const router = useRouter()
    const InfoForm = ref<null | IinfoRef>(null)
    const ruleForm = ref<Iform>({
      entName: '',
      contact: '',
      email: '',
      sc: '',
      registerMoney: '',
      registerAddress: '',
      isHigh: '',
      isEquipment: '',
      operateIncome: '',
      totolProperty: '',
      employees: '',
      mainProduct: '',
      businessAddress: ''
    })
    watch(ruleForm, () => {
      let arr = 0
      for (const key in ruleForm.value) {
        if (ruleForm.value[key] !== '' && key !== 'registerMoney' && key !== 'registerAddress' && key !== 'sc') {
          arr++
        }
      }
      context.emit('update:percentage', arr * 10)
    }, {
      deep: true
    })
    const submit = () => {
      if (InfoForm.value) {
        InfoForm.value.validate((valid) => {
          if (valid) {
            verify(Object.assign({}, ruleForm.value)).then(() => {
              add()
              router.push({ path: '/perfectInfoResult' })
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
    onMounted(() => {
      entInfo().then(res => {
        for (const key in ruleForm.value) {
          for (const _key in res.data) {
            if (key === _key && res.data[key]) {
              ruleForm.value[key] = res.data[key]
            }
          }
        }
      })
    })
    return {
      ruleForm,
      rules,
      InfoForm,
      submit
    }
  }
})
</script>
<style lang='scss' scoped>
.InfoForm {
  box-sizing: border-box;
  width: 897px;
  margin: auto;
  padding-top: 46px;
  background: #ffffff;
  opacity: 1;
  border-radius: 6px;
  padding-bottom: 45px;
}
:deep(.el-form) {
  display: flex;
  flex-direction: column;
  .el-form-item {
    display: flex;
    margin: auto;
    margin-top: 32px;
    .el-form-item__label {
      width: 150px !important;
      font-weight: 400;
      color: #000;
    }
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
  & > .el-form-item:first-of-type {
    margin-top: 0;
  }
  .el-form-item__content {
    min-width: 311px;
  }
}
:deep(.el-input) {
  width: 311px;
}
:deep(.el-radio__inner) {
  width: 22px;
  height: 22px;
}
:deep(.el-radio__input.is-checked .el-radio__inner):after {
  transform: rotate(45deg) scaleY(1);
}
:deep(.el-radio__inner)::after {
  box-sizing: content-box;
  content: '';
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 11px;
  left: 6px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 5.5px;
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
  background-color: transparent;
  border-radius: 0;
}
.formBtn {
  margin-top: 30px;
  width: 102px;
  height: 48px;
  background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
  box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
  opacity: 1;
  border-radius: 25px;
  line-height: 48px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  margin-left: 395px;
}
.tip {
  margin-left: 328px;
  align-items: center;
  margin-top: 27px;
  img {
    width: 19px;
    height: 23px;
  }
  span {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
}
</style>
