
import { defineComponent, onMounted, ref, watch } from 'vue'
import rules from './validate'
import { entInfo, verify, add } from '@/api/preciseMatch'
import { useRouter } from 'vue-router'
import BaseGetInfoInput from '@/common/BaseGetInfoInput.vue'

interface IinfoRef {
  validate: (cb: (params: boolean) => void) => void;
}
interface Iform {
  entName: string;
  contact: string;
  email: string;
  sc: string;
  registerMoney: string;
  registerAddress: string;
  isHigh: string;
  isEquipment: string;
  operateIncome: string;
  totolProperty: string;
  employees: string;
  mainProduct: string;
  businessAddress: string;
  [key: string]: any;
}
interface Ires {
  status: string;
  message: string;
  data?: any;
}

export default defineComponent({
  components: {
    BaseGetInfoInput
  },
  props: {
    percentage: {
      type: Number,
      required: true
    }
  },
  setup (props, context) {
    const router = useRouter()
    const InfoForm = ref<null | IinfoRef>(null)
    const ruleForm = ref<Iform>({
      entName: '',
      contact: '',
      email: '',
      sc: '',
      registerMoney: '',
      registerAddress: '',
      isHigh: '',
      isEquipment: '',
      operateIncome: '',
      totolProperty: '',
      employees: '',
      mainProduct: '',
      businessAddress: ''
    })
    watch(ruleForm, () => {
      let arr = 0
      for (const key in ruleForm.value) {
        if (ruleForm.value[key] !== '' && key !== 'registerMoney' && key !== 'registerAddress' && key !== 'sc') {
          arr++
        }
      }
      context.emit('update:percentage', arr * 10)
    }, {
      deep: true
    })
    const submit = () => {
      if (InfoForm.value) {
        InfoForm.value.validate((valid) => {
          if (valid) {
            verify(Object.assign({}, ruleForm.value)).then(() => {
              add()
              router.push({ path: '/perfectInfoResult' })
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
    onMounted(() => {
      entInfo().then(res => {
        for (const key in ruleForm.value) {
          for (const _key in res.data) {
            if (key === _key && res.data[key]) {
              ruleForm.value[key] = res.data[key]
            }
          }
        }
      })
    })
    return {
      ruleForm,
      rules,
      InfoForm,
      submit
    }
  }
})
