
import { defineComponent, ref } from 'vue'
import PreciseMatchInfo from './components/PreciseMatchInfo.vue'
export default defineComponent({
  components: {
    PreciseMatchInfo
  },
  setup() {
    const percentage = ref(0)
    const isShow = ref(false)
    const goBack = () => {
      window.history.go(-1)
    }
    return {
      percentage,
      isShow,
      goBack
    }
  }
})
