<template>
  <div id="perfectInfo" class="base_min_width">
    <!-- 升级维护中 -->
    <div class="upgrading">
      <img src="@/assets/images/preciseMatch/upgrading.png" alt="" />
      <div class="upgradingBtn" @click="goBack()">返回上一页</div>
    </div>

    <!-- 暂时关闭填写表单 -->
    <div v-if="isShow">
      <div class="line">
        <el-progress
          :percentage="percentage"
          stroke-linecap="square"
          color="linear-gradient(266deg, #F1BB65 0%, #FDF7D6 100%);"
          :show-text="false"
        >
        </el-progress>
        <!-- <div class="line_content"></div> -->
      </div>
      <PreciseMatchInfo v-model:percentage="percentage" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import PreciseMatchInfo from './components/PreciseMatchInfo.vue'
export default defineComponent({
  components: {
    PreciseMatchInfo
  },
  setup() {
    const percentage = ref(0)
    const isShow = ref(false)
    const goBack = () => {
      window.history.go(-1)
    }
    return {
      percentage,
      isShow,
      goBack
    }
  }
})
</script>
<style lang="scss" scoped>
#perfectInfo {
  box-sizing: border-box;
  padding-top: 103px;
  width: 100%;
  background: #f9f9f9;
  padding-bottom: 85px;
  .line {
    height: 7px;
    width: 100%;
    background: #edefed;
    opacity: 1;
    position: fixed;
    left: 0;
    top: 103px;
    width: 100%;
    z-index: 9;

    :deep(.el-progress-bar__outer) {
      height: 7px !important;
      border-radius: 0 !important;
      .el-progress-bar__inner {
        border-radius: 0 100px 100px 0;
        background: linear-gradient(
          266deg,
          #f1bb65 0%,
          #fdf7d6 100%
        ) !important;
      }
    }

    .line_content {
      width: 328px;
      height: 100%;
      background: linear-gradient(266deg, #f1bb65 0%, #fdf7d6 100%);
      opacity: 1;
      border-radius: 10px;
    }
  }
  .upgrading {
    img {
      width: 546px;
      height: 331px;
      margin: 140px auto 90px;
    }
    .upgradingBtn {
      width: 292px;
      height: 56px;
      background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
      opacity: 1;
      border-radius: 28px;
      font-size: 20px;
      color: #230a01;
      line-height: 56px;
      margin: 0 auto 65px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
