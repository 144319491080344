import { ref } from 'vue'
const { validatePass, validateContact, validateEmail } = (function getValidate() {
  const validatePass = (rule: string, value: string, callback: (cb?: any) => void) => {
    if (value === '') {
      callback(new Error('请输入工商注册企业名称'))
    } else {
      callback()
    }
  }
  const validateContact = (rule: string, value: string, callback: (cb?: any) => void) => {
    if (value === '') {
      callback(new Error('请输入联系人'))
    } else {
      callback()
    }
  }
  const validateEmail = (rule: string, value: string, callback: (cb?: any) => void) => {
    const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (value === '') {
      callback(new Error('请输入邮箱地址'))
    } else if (value && !regEmail.test(value)) {
      callback(new Error('请输入正确的邮箱地址'))
    } else {
      callback()
    }
  }
  return {
    validatePass,
    validateContact,
    validateEmail
  }
})()
const rules = ref({
  entName: [
    { validator: validatePass, trigger: 'blur', required: true }
  ],
  contact: [
    { validator: validateContact, trigger: 'blur', required: true }
  ],
  email: [
    { validator: validateEmail, trigger: 'blur', required: true }
  ],
  isHigh: [
    { required: true, message: '请选择是否有高层次人才', trigger: 'change' }
  ],
  isEquipment: [
    { required: true, message: '请选择是否有设备投入', trigger: 'change' }
  ],
  operateIncome: [
    { required: true, message: '请选择营收规模', trigger: 'change' }
  ],
  totolProperty: [
    { required: true, message: '请选择净资产', trigger: 'change' }
  ]
})
export default rules
